import { useContext, useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import DebugContext, { DebugContextType } from 'src/contexts/DebugContext';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { disableAvatarDebugging } from 'src/utils';
import {
  useSession,
  useGetAvatarURL,
  useVisible,
  useMetaHumanEvent,
  useBreakpoint,
  useTheme,
} from 'src/hooks';
import { IFrame } from 'src/components/IFrame';
import { ChatMode, MetaHumanEvent, isMetaHumanStreamingEvent } from 'src/types';
import { AvatarFrameButtonsLayout } from './components/AvatarFrameButtonsLayout';
import SessionContext, {
  SessionContextType,
} from 'src/contexts/SessionContext';
import {
  FLOATING_PADDING,
  DEFAULT_FLOATING_WIDTH,
  DEFAULT_FLOATING_HEIGHT,
  SETTINGS_PANEL_EXPANDED_WIDTH,
  SETTINGS_PANEL_WIDTH,
  LEFT_PANEL_WIDTH,
  ANIMATION_TIMEOUT,
} from 'src/constants';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/all';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(Draggable);

type AvatarFrameProps = {
  isFloating?: boolean;
};

export const AvatarFrame = ({ isFloating = false }: AvatarFrameProps) => {
  const { appUser, chatMode, avatarQueue } = useSession();
  const { isSettingsPanelExpanded } = useTheme();
  const { debugMode } = useContext<DebugContextType>(DebugContext);
  const { avatarURL } = useGetAvatarURL();
  const { setAvatarIsBeingDragged, showAvatarQueueBanner } =
    useContext<AvatarModeContextType>(AvatarModeContext);
  const { isShowAvatarSelect } = useContext<SessionContextType>(SessionContext);
  const { isVisible, handleVisibilitySet, handleVisibilityRemove } =
    useVisible(true);
  const [applyFullscreen, setApplyFullscreen] = useState(false);
  const { isMobileOrTablet } = useBreakpoint();

  const { avatarFrameRef } = useContext(ForwardRefContext);

  const avatarFrameHandleRef = useRef<HTMLDivElement>(null);

  const { contextSafe } = useGSAP({ scope: avatarFrameRef || document.body });

  const updateBounds = contextSafe(() => {
    if (avatarFrameRef?.current instanceof HTMLDivElement) {
      const floatingPadding = applyFullscreen ? 0 : FLOATING_PADDING;
      const draggable = Draggable.get(avatarFrameRef.current);

      if (draggable) {
        const { width = window.innerWidth, height = window.innerHeight } =
          document.documentElement.getBoundingClientRect();
        const {
          width: frameWidth = DEFAULT_FLOATING_WIDTH,
          height: frameHeight = DEFAULT_FLOATING_HEIGHT,
        } = avatarFrameRef.current.getBoundingClientRect();

        const newBounds = {
          minX: floatingPadding,
          maxX: width - FLOATING_PADDING - frameWidth,
          minY: floatingPadding,
          maxY: height - FLOATING_PADDING - frameHeight,
        };

        draggable.applyBounds(newBounds);
      }
    }
  });

  const updatePosition = contextSafe(() => {
    if (avatarFrameRef?.current instanceof HTMLDivElement) {
      const rightOffset = isMobileOrTablet
        ? 0
        : isSettingsPanelExpanded
          ? SETTINGS_PANEL_EXPANDED_WIDTH
          : SETTINGS_PANEL_WIDTH;

      gsap.set(avatarFrameRef.current, {
        x:
          window.innerWidth - LEFT_PANEL_WIDTH - rightOffset - FLOATING_PADDING,
        y: FLOATING_PADDING,
      });
    }
  });

  useEffect(() => {
    disableAvatarDebugging(appUser.user_id, debugMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateBounds();
  }, [isSettingsPanelExpanded, updateBounds]);

  // create a draggable avatar frame instance
  useGSAP(() => {
    if (
      !(
        avatarFrameRef?.current &&
        avatarFrameHandleRef.current &&
        avatarFrameRef.current?.parentElement &&
        isFloating
      )
    ) {
      return;
    }

    window.addEventListener('resize', updateBounds);

    Draggable.zIndex = 1000000;
    updatePosition();

    Draggable.create(avatarFrameRef.current, {
      edgeResistance: 1,
      throwProps: true,
      inertia: true,
      trigger: [avatarFrameHandleRef.current],
      onDrag: updateBounds,
      onDragEnd: () => {
        updateBounds();
        setAvatarIsBeingDragged(false);
      },
      onDragStart: () => {
        setAvatarIsBeingDragged(true);
      },
      bounds: window,
      liveSnap: true,
    });

    return () => {
      window.removeEventListener('resize', updateBounds);
    };
  }, [
    isFloating,
    avatarFrameRef?.current,
    avatarFrameHandleRef.current,
    isSettingsPanelExpanded,
  ]);

  useMetaHumanEvent(
    useCallback(
      (metaHumanEvent: MetaHumanEvent) => {
        if (isMetaHumanStreamingEvent(metaHumanEvent)) {
          if (metaHumanEvent.state && !isVisible) {
            handleVisibilitySet();
          } else {
            if (isVisible) {
              handleVisibilityRemove();
            }
          }
        }
      },
      [handleVisibilitySet, handleVisibilityRemove, isVisible],
    ),
  );

  const handleAvatarFullscreen = (value: boolean) => {
    setApplyFullscreen(value);
    if (!value && isFloating) {
      updatePosition();
    }
  };

  const avatarClasses = classNames('nj-meta-human', {
    floating: isFloating && !showAvatarQueueBanner,
    'enable-fullscreen': applyFullscreen,
  });

  return (
    <CSSTransition
      in={chatMode === ChatMode.AVATAR && avatarQueue?.available}
      timeout={ANIMATION_TIMEOUT}
      classNames={!isFloating ? 'nj-animate-avatar-frame-width' : ''}
      unmountOnExit
      nodeRef={avatarFrameRef}
    >
      <div className={avatarClasses} ref={avatarFrameRef}>
        {(isFloating || isShowAvatarSelect) && (
          <div
            className={classNames('nj-meta-human--overlay', {
              visible: isVisible,
            })}
            ref={avatarFrameHandleRef}
          />
        )}

        <AvatarFrameButtonsLayout
          isVideoFloating={isFloating}
          onFullscreen={handleAvatarFullscreen}
        />
        {avatarURL && (
          <IFrame
            url={avatarURL}
            allowFullScreen={true}
            userId={appUser.user_id}
            isFloating={isFloating}
            debug={debugMode}
          />
        )}
      </div>
    </CSSTransition>
  );
};
