import cn from 'classnames';
import { useTheme, useThreads } from 'src/hooks';
import { AvatarImage } from 'src/components/AvatarImage';
import styles from './MainNavToggleButton.module.scss';

interface MainNavToggleButtonProps {
  className?: string;
}

/**
 * MainNavToggleButton for opening tasks list.
 */
export const MainNavToggleButton = ({
  className,
}: MainNavToggleButtonProps) => {
  const { toggleTasksListExpanded } = useTheme();

  const { conversations } = useThreads();
  const count = conversations.filter(
    (conversation) => conversation.number_tasks_require_attention !== 0,
  ).length;

  const handleClick = () => {
    toggleTasksListExpanded();
  };

  return (
    <button
      className={cn(styles.root, {
        className,
        [styles.notification]: count > 0,
      })}
      onClick={handleClick}
    >
      <AvatarImage />
    </button>
  );
};
