import { colord, RgbaColor } from 'colord';
import {
  performanceMarkerStart,
  performanceMarkerEnd,
} from './performanceMarkers';
import { translateTextToSpecificLanguage } from './translateText';
import {
  AvatarAnimations,
  CreateAvatarVideoRequest,
  AvatarVideoPresetName,
  Message,
  isResearchData,
  isResearchCard,
  isResearchTaskCreationCard,
  isSchedulerCreationCard,
  isCodeTaskCreationCard,
  isChitChatCard,
} from 'src/types';
import { MovieStudioFormData, isError } from 'src/types';
import {
  DEFAULT_AVATAR_ID,
  DEFAULT_AVATAR_ENVIRONMENT,
  DEFAULT_AVATAR_VOICE_ID,
  DEFAULT_LANGUAGE,
  OFFLINE_RENDER_S3_BUCKET,
} from 'src/constants/metahuman';
import { removeReferencesInBrackets } from './sanitize';
import log from 'src/utils/logger';
import { getBaseHeaders } from 'src/store/services/config';
import { isSafari } from 'react-device-detect';
import { env } from 'src/env';

export const MATCHMAKER_SERVER = env.REACT_APP_AVATAR_MATCHMAKER_SERVER || '';
export const AVATAR_FALLBACK = env.REACT_APP_AVATAR_FALLBACK || '';

export const META_HUMAN_OFFLINE_RENDER_URL =
  env.REACT_APP_META_HUMAN_OFFLINE_RENDER_URL || '';

// Beta users
export const BABAK_ID = '9065526c-7371-4717-a71c-b13911cb3710';
export const ELLA_ID = 'b2cffd93-3ce2-4742-b58a-ad1efe397b54';
export const ANDRES_ID = '3064bd91-d05e-4c55-886b-05131f5c2303';
export const SAM_ID = '5c8bd410-3511-40c5-ad7d-4acb8a81bbda';
export const DIVYA_ID = '2d46c818-4ee8-4569-b30b-9d8db8c02cb2';

export const AVATAR_T4 = '411d0b14-1fbf-4b25-9dd6-5f499a509fa2';
export const AVATAR_L4 = '5d0e574e-76fd-478d-855c-94caddb2e52a';
export const AVATAR_L4_8 = '55a27482-785e-47f6-83d0-4b8fc9c75295';
export const AVATAR_L4_24 = 'd6a197d6-baa5-4a73-9843-4e67730ef520';

// Prod users
export const BABAK_ID_PROD = 'a4858618-e18b-4f10-8066-64adea22a64e';
export const SAM_ID_PROD = '97118fde-5409-4d0f-8de0-e6ddba4c139b';

/**
 * interruptMetahuman sends a signal to Meta Human to stop talking.
 * @param userId string
 * @param debug boolean
 */
export async function interruptMetahuman(userId: string, debug = false) {
  const avatarData = { Category: 'interrupt' };
  if (debug) {
    log.debug('Sending MetaHuman interrupt signal, user_id: ', userId);
  }

  try {
    await sendAvatarMessage(JSON.stringify(avatarData), debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * animateMetahuman() sends an animation signal to avatar to make it think, listen etc.
 * @param userId string
 * @param debug boolean
 * @param emotion AvatarAnimations
 */
export async function animateMetahuman(
  userId: string,
  emotion: AvatarAnimations,
  debug = true,
) {
  const avatarData = { Category: 'animation', state: emotion };
  if (debug) {
    log.debug('Changing metahuman animation, user_id: ', userId);
  }

  try {
    await sendAvatarMessage(JSON.stringify(avatarData), debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * Some users have special Avatar instances dedicated to them
 * TODO: Move this to a user setting
 * @param userId string
 * @returns string
 */
export function getUserInstance(
  userId: string,
  debug = false,
): string | undefined {
  // temporarily give Babak his own environment
  if (BABAK_ID === userId || BABAK_ID_PROD === userId || DIVYA_ID === userId) {
    // TODO: This probably should be a settings in the user db
    return 'https://avatar-babak.public.sandbox-gcp.myninja.ai';
  }

  // if (SAM_ID === userId || SAM_ID_PROD === userId){
  //   return "https://avatar-sam-prod.myninja.ai";
  // }
}

/**
 * Get Avatar URL as a base URL for the avatar api.
 * @param userId string
 * @param debug boolean
 * @returns string
 */
export async function getAvatarURL(
  userId: string,
  debug = true,
): Promise<string> {
  let avatar_url = '';
  // Some users have Avatar instances specific to them
  /*
  let avatar_url = getUserInstance(userId);
  if (avatar_url) {
    log.debug('Using SPECIAL AVATAR INSTANCE: ' + avatar_url);
    return avatar_url;
  }*/

  if (debug) {
    log.debug('Using MATCHMAKER_SERVER: ' + MATCHMAKER_SERVER);
  }
  if (MATCHMAKER_SERVER.length > 1) {
    avatar_url = await getAvatarInstanceURL(MATCHMAKER_SERVER, userId, debug);
    if (avatar_url) {
      if (debug) {
        log.debug('Using MATCHMAKER AVATAR INSTANCE: ' + avatar_url);
      }
      return avatar_url;
    }
  }

  avatar_url = AVATAR_FALLBACK;
  if (debug) {
    log.debug('Using LEGACY AVATAR INSTANCE (as fallback): ' + avatar_url);
  }
  return avatar_url;
}

/**
 * Call the matchmaker server and get a new Avatar instance URL to use
 * @param userId string
 * @returns string
 */
export async function getAvatarInstanceURL(
  match_maker_server: string,
  userID: string,
  debug = true,
): Promise<string> {
  // There is a weird issue that when trying to access the signallingserver
  // endpoint with a async requests (i.e via a Redux createAPI) the response
  // comes back empty, even if it returns 200. I spent a bunch of time debugging
  // this with no luck, for now the workaround is to do a sync call and store
  // the result un a global var.
  try {
    const response = await fetch(
      `${match_maker_server}/signallingserver?userId=${userID}`,
      {
        method: 'GET',
        headers: await getBaseHeaders(
          new Headers({
            'Content-Type': 'application/json',
          }),
        ),
      },
    );

    const res_text = await response.text();
    log.debug('MATCHMAKER response ' + res_text);

    if (response.status === 200) {
      const parsedResponse = JSON.parse(res_text);
      if (parsedResponse['signallingServer'].length > 1) {
        const url = new URL(match_maker_server);

        const avatarInstanceUrl =
          url.protocol + '//' + parsedResponse['signallingServer'];
        log.debug(`Using AVATAR_INSTANCE_URL:` + avatarInstanceUrl);
        return avatarInstanceUrl;
      }
    }
  } catch (error) {
    log.error(error);
    return '';
  }

  return '';
}

/**
 * getAvatarToTalkURL provides URL handle to send speech to.
 * @param userId string
 * @param debug boolean
 * @returns string
 */
export function getAvatarToTalkURL(userId: string, debug = false): string {
  return getAvatarURL(userId, debug) + '/input/text';
}

/**
 * GET Endpoint that sends data to iFrame to talk on behalf of OpenAI.
 * @param speech string
 * @param userId string
 */
export async function letMetaHumanTalk(
  speech: string,
  userId: string,
  voiceID: string,
  audioLocale: string,
  debug = false,
) {
  const text = speech.replace(/\s/gi, '%20');
  // (olha): we expect that CE send all responses in English
  // (olha): we send text to the Avatar without translation for better performance

  //let avatarCCLocale = CCLocale;

  /**
   * For non english languages we send the translation to the avatar, so that avatar
   * does not have to translate it.
   */
  // if (audioLocale !== 'en-US') {
  //   const avatarAudioLanguage = audioLocale.split('-')[0];

  //   const translationMap = await translateText(speech, [avatarAudioLanguage]);
  //   const translation = translationMap.get(speech)?.get(avatarAudioLanguage);
  //   if (translation) {
  //     text = translation.replace(/\s/gi, '%20');

  //     // Replace the CC locale with the audio locale
  //     //avatarCCLocale = audioLocale;
  //   }
  // }

  // The avatar server expects the CCLang to the lang of the text being sent to
  // This is a separate setting than the CC (closed captioning)
  // shown in the FE
  // https://avatar.myninja.ai/input/text?AudioLang=en-US&CCLang=en-US&data=How%20are%20you%20today?
  /*
  const endpoint = `${getAvatarToTalkURL(
    userId,
    debug,
  )}?AudioLang=${audioLocale}&CCLang=en-US&data=${text}&voiceID=${voiceID}`;
  if (debug) {
    log.debug('AVATAR DEBUG > ', endpoint);
  }

  performanceMarkerStart('avatar_response_time');
  const result = await fetchAvatar(endpoint);
  performanceMarkerEnd('avatar_response_time', {}, debug);*/

  const avatarData = {
    Category: 'input',
    data: text,
    AudioLang: audioLocale,
    CClang: 'en-US',
    VoiceID: voiceID,
  };
  if (debug) {
    log.debug('Sending text to the avatar ', text);
  }

  try {
    performanceMarkerStart('avatar_response_time');
    const result = await sendAvatarMessage(JSON.stringify(avatarData), debug);
    performanceMarkerEnd('avatar_response_time', {}, debug);

    if (debug) {
      log.debug('CALLING FETCH & RESULT =', result);
    }
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * isFullScreenEnabled tests user machine for fullscreen.
 * @returns boolean
 */
export function isFullScreenEnabled() {
  return (
    !isSafari &&
    ('fullscreenEnabled' in document ||
      'webkitIsFullScreen' in document ||
      'mozFullScreen' in document ||
      'msFullscreenElement' in document)
  );
}

/**
 * goFullScreen expands iframe to be fullscreen.
 * @param element
 */
export function goFullScreen(
  element?: HTMLVideoElement | HTMLIFrameElement | HTMLDivElement,
) {
  if (!element) return;

  const requestMethod = element.requestFullscreen;

  if (requestMethod) {
    requestMethod.apply(element);
  }
}

/**
 * exitFullScreen contracts iframe to be fullscreen.
 * @param element
 */
export function exitFullscreen() {
  if (!!document.fullscreenElement) {
    document.exitFullscreen();
  }
}

/**
 * change avatar from avatar selector.
 * @param userId string
 * @param avatarName string
 * @param debug boolean
 */
export async function changeMetahumanAvatar(
  userId: string,
  avatarName: string,
  debug: boolean,
) {
  const avatarData = { Category: 'avatar', name: avatarName };
  if (debug) {
    log.debug('Changing metahuman avatar & their name, user_id: ', userId);
  }

  try {
    await sendAvatarMessage(JSON.stringify(avatarData), debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * change avatar appearance (environment, camera angle, background color).
 * @param userId string
 * @param environment string
 * @param cameraIndex string
 * @param rgbColor string
 * @param debug boolean
 */
export async function changeMetahumanAppearance(
  userId: string,
  environment: string,
  cameraIndex: number,
  rgbColor: string,
  debug = false,
) {
  const avatarData = {
    Category: 'environment',
    name: environment,
    camera: cameraIndex,
    extra: rgbColor,
  };

  if (debug) {
    log.debug('Changing metahuman avatar appearance, user_id: ', userId);
  }

  try {
    await sendAvatarMessage(JSON.stringify(avatarData), debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * change avatar camera angle.
 * @param userId: string,
 * @param cameraIndex string
 * @param debug boolean
 */
export async function changeMetahumanCameraAngle(
  userId: string,
  cameraIndex: number,
  debug = false,
) {
  const avatarData = { Category: 'camera', index: cameraIndex };
  if (debug) {
    log.debug('Changing metahuman avatar camera angle to ', cameraIndex);
  }

  try {
    await sendAvatarMessage(JSON.stringify(avatarData), debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

export const hexToRgb = (hexColor: string): RgbaColor => {
  return colord(hexColor).toRgb();
};

export const toDecimalFraction = (value: number) => {
  return (value / 255).toFixed(6);
};

export const hexColorToMetahumanFormat = (hexColor: string) => {
  const { r, g, b } = hexToRgb(hexColor);

  return `${toDecimalFraction(r)},${toDecimalFraction(g)},${toDecimalFraction(
    b,
  )}`;
};

/**
 * Prepare data for offline rendering
 * @param value
 * @param userEmail
 * @returns
 */
export async function getOfflineRenderRequestData(
  value: Partial<MovieStudioFormData>,
  userEmail: string,
): Promise<CreateAvatarVideoRequest> {
  const {
    title,
    avatar,
    AudioLang,
    voiceID,
    emotion,
    cameraIndex,
    environment,
    backgroundColor,
    script,
    _4k,
    portrait,
    square,
  } = value;

  if (!script) {
    return {} as CreateAvatarVideoRequest;
  }

  const scriptData = await Promise.all(
    script.map(async ({ text, imageURL }) => ({
      text: text
        ? await translateTextToSpecificLanguage(
            text,
            AudioLang || DEFAULT_LANGUAGE,
          )
        : '',
      imageURL,
      emotion: emotion || 'neutral',
      caption: 'Introduction', // remove hardcode
      AudioLang: AudioLang || DEFAULT_LANGUAGE,
    })) || [],
  );

  const videoPresets = [];

  _4k &&
    videoPresets.push({
      PresetName: AvatarVideoPresetName._4K_RAW,
      Width: 3840,
      Height: 2160,
      FPS: 60.0,
      Quality: 0,
    });

  portrait &&
    videoPresets.push({
      PresetName: AvatarVideoPresetName.HD_VERTICAL,
      Width: 1080,
      Height: 1920,
      FPS: 30.0,
      Quality: 23,
    });

  square &&
    videoPresets.push({
      PresetName: AvatarVideoPresetName.SQUARE,
      Width: 1080,
      Height: 1080,
      FPS: 30.0,
      Quality: 23,
    });

  const data: CreateAvatarVideoRequest = {
    email: userEmail,
    s3bucket: OFFLINE_RENDER_S3_BUCKET,
    title: title || '',
    text: scriptData[0].text, // Remove when will be work script
    avatar: avatar || DEFAULT_AVATAR_ID,
    backgroundColor: backgroundColor
      ? hexColorToMetahumanFormat(backgroundColor)
      : '',
    CCLang: AudioLang || DEFAULT_LANGUAGE,
    AudioLang: AudioLang || DEFAULT_LANGUAGE,
    voiceID: voiceID || DEFAULT_AVATAR_VOICE_ID,
    emotion: emotion || 'neutral',
    cameraIndex: Number(cameraIndex),
    environment: environment || DEFAULT_AVATAR_ENVIRONMENT,
    sequence: 'talk about',
    prerollTime: 0.4,
    postrolltime: 0.5,
    script: scriptData,
    videoPresets,
    preview: false,
  };

  return data;
}

/**
 * Make an API request for offline rendering
 * @param data MovieStudioFormData
 * @param userEmail string
 * @returns
 */
export async function requestOfflineRender(
  data: MovieStudioFormData,
  userEmail: string,
  debug = false,
) {
  const requestData = await getOfflineRenderRequestData(data, userEmail);
  const requestJson = JSON.stringify(requestData);
  const avatarData = { Category: 'create_task', json: requestJson };

  try {
    await sendAvatarMessage(JSON.stringify(avatarData), debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * Make an API request for paying preview in the offline rendering
 * @param fieldsValue
 * @param userEmail
 * @param userId
 */
export async function playOfflinePreview(
  fieldsValue: Partial<MovieStudioFormData>,
  userEmail: string,
  userId: string,
  debug = false,
) {
  const data = await getOfflineRenderRequestData(fieldsValue, userEmail);
  const jsonData = JSON.stringify({ ...data, preview: true });

  const avatarData = {
    Category: 'renderTask',
    json: jsonData,
  };

  try {
    await sendAvatarMessage(JSON.stringify(avatarData), debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * sendAvatarMessage() send a message to an avatar instance.
 * @param message string
 */
export async function sendAvatarMessage(message: string, debug: boolean) {
  // Get the div element
  const njMetaHumanDiv = document.querySelector('.nj-meta-human');

  if (njMetaHumanDiv) {
    // Get the first iframe inside the div
    const iframe = njMetaHumanDiv.querySelector('iframe');
    if (iframe) {
      const iframeContentWindow = iframe.contentWindow;
      if (iframeContentWindow) {
        const sanitizedMessage = message.replace(/%20/g, ' ');
        iframeContentWindow.postMessage(sanitizedMessage, '*');
        if (debug) {
          log.debug('sendAvatarMessage ' + message);
        }
      } else {
        throw new Error('Failed to access the content of iframe window.');
      }
    }
  }
}

/**
 * Fetch a Avatar url applying the proper auth
 * @param url string
 */
export async function fetchAvatar(url: string) {
  try {
    if (isAuthEnv(url)) {
      return await fetch(url, {
        method: 'GET',
        headers: await getBaseHeaders(
          new Headers({
            'Content-Type': 'application/json',
          }),
        ),
        mode: 'cors',
      });
    } else {
      // AWS based envs don't have auth
      return await fetch(url, {
        method: 'GET',
        mode: 'no-cors',
      });
    }
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * Return true if this is an env that requires auth
 * @param url string
 */
export function isAuthEnv(url: string): boolean {
  // AWS envs don't use auth
  if (
    url.includes('avatar-dev.myninja.ai') ||
    url.includes('avatar-prod.myninja.ai') ||
    url.includes('avatar-sam-prod.myninja.ai') ||
    // TODO: V100 instance has the old Pixelstream with no cognito support.
    url.includes('avatar-babak.public.sandbox-gcp.myninja.ai') ||
    url.includes('prod-fallback.public.beta-gcp.myninja.ai')
  ) {
    return false;
  }
  return true;
}

/**
 * getStreamableMessageFromMessage() obtains message for avatar streaming.
 * @param message Message
 * @returns string
 */
export function getStreamableAvatarChunks(message: Partial<Message>): {
  messageToStream?: string;
} {
  const { payload } = message;
  if (isResearchData(payload)) {
    const { summary } = payload;
    return {
      messageToStream: removeReferencesInBrackets(summary),
    };
  } else if (isResearchCard(payload)) {
    const search_result = payload.data?.search_result;

    const summary = search_result?.summary || '';
    return {
      messageToStream: removeReferencesInBrackets(summary),
    };
  } else if (isChitChatCard(payload)) {
    const output = payload.data?.ninja_model_output || '';
    return {
      messageToStream: removeReferencesInBrackets(output + '. '),
    };
  }

  return {};
}

/**
 * getStreamableMessageForAvatar() obtains message for avatar streaming.
 * @param message Message
 * @returns string
 */
export function getStreamableMessageForAvatar(
  message: Partial<Message>,
): string {
  const { payload } = message;

  if (isResearchTaskCreationCard(payload)) {
    const summary = payload.research_card?.data?.search_result?.summary || '';
    return removeReferencesInBrackets(summary);
  }

  if (isChitChatCard(payload)) {
    return removeReferencesInBrackets(payload.data?.ninja_model_output || '');
  }

  if (isSchedulerCreationCard(payload)) {
    return 'I am working on it.';
  }

  if (isCodeTaskCreationCard(payload)) {
    return 'Please see the response in the chat message.';
  }

  return message.content ? removeReferencesInBrackets(message.content) : '';
}

/**
 * disableAvatarDebugging() disables streaming server
 * debugging messages in console.
 * TODO(ella): Follow up with Angel, he is implementing a fix,
 * this won't work just yet.
 * @param userId string
 * @param debug boolean
 */
export async function disableAvatarDebugging(userId: string, debug: boolean) {
  if (debug) {
    log.debug('Stopping avatar server logging.');
  }
  try {
    await sendAvatarMessage('debugmode=off', debug);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * muteAvatar() enables/disables avatar audio.
 * todo(ella): develop further from this placeholder.
 */
export async function muteAvatar() {
  const avatarData = { Category: 'mute' };
  try {
    await sendAvatarMessage(JSON.stringify(avatarData), false);
  } catch (error: unknown) {
    log.error(isError(error) ? error.message : error);
  }
}

/**
 * getQueuePosition() returns the position in the queue: 1st, 2nd, 3rd, 4th, etc.
 * @param position number
 * @returns string
 */
export function getQueuePosition(position = 0): string {
  return position > 0
    ? `${position}${
        position === 1
          ? 'st'
          : position === 2
            ? 'nd'
            : position === 3
              ? 'rd'
              : 'th'
      }`
    : '';
}
