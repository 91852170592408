import { Stack } from '@phosphor-icons/react';
import { ChitChatCard } from 'src/types';
import { prepareExternalModelsReferences } from 'src/utils';
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { ThreadSummary } from '../ThreadSummary';
import { ExternalModelReferencesList } from '../ExternalModelReferencesList';
import styles from './ThreadChitChatCard.module.scss';
import { SettingsMessageResearchCodeCard } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/SettingsMessageResearchCodeCard';
import { useSession } from 'src/hooks';

interface ThreadChatCardProps {
  chitChatCard: ChitChatCard;
  isStreaming?: boolean;
  originalQuery?: string;
}

export const ThreadChitChatCard = ({
  chitChatCard,
  isStreaming,
  originalQuery,
}: ThreadChatCardProps) => {
  const { isOpenTier } = useSession();
  const { data, task_id } = chitChatCard;
  const { ninja_model_output, referenced_model_summary } = data || {};

  const { summary, references } = referenced_model_summary || {};

  return (
    <div className={styles.root}>
      {ninja_model_output && (
        <ThreadMessageContent
          content={ninja_model_output}
          isStreaming={isStreaming}
        />
      )}

      {referenced_model_summary && (
        <ThreadSummary
          title="Summary from external models"
          content={summary}
          IconComponent={Stack}
          isStreaming={isStreaming}
          isCollapsed
          colorPalette="light-blue"
          references={prepareExternalModelsReferences(
            references || {},
            originalQuery,
          )}
        />
      )}

      {references && (
        <ExternalModelReferencesList
          references={references}
          originalQuery={originalQuery}
        />
      )}

      {!isOpenTier && (
        <SettingsMessageResearchCodeCard
          cardType={task_id ? 'chit-chat-card' : 'chit-chat'}
          referencedModelSummary={referenced_model_summary}
          isStreaming={isStreaming}
        />
      )}
    </div>
  );
};
