import {
  ChitChatCard,
  CodeTaskCreationCard,
  ConversationRole,
  Message,
  MessageType,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ImageCard,
} from 'src/types';
import { ThreadMessageContent } from '../ThreadMessageContent';
import { ThreadCombinedCard } from '../ThreadCombinedCard';
import { ThreadResearchCard } from '../ThreadResearchCard';
import { ThreadScheduleCard } from '../ThreadScheduleCard';
import { DebugMessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/DebugMessageWrapper';
import { ThreadCodeCard } from 'src/components/FlatAppearance/components/ThreadCodeCard';
import { ThreadErrorMessageContent } from 'src/components/FlatAppearance/components/ThreadErrorMessageContent';
import { ErrorPayload } from 'src/types/models/ErrorPayload';
import log from 'src/utils/logger';
import { ThreadChitChatCard } from '../ThreadChitChatCard';
import { ThreadImageGenerationCard } from 'src/components/FlatAppearance/components/ThreadImageGenerationCard';

import { CollapsibleTitle } from 'src/components/CollapsibleTitle';
import {
  FileCsv,
  FileDoc,
  FilePdf,
  FileText,
  FileTxt,
  FileXls,
} from '@phosphor-icons/react';
import { FILE_REGEX, EDIT_FILE_REGEX } from 'src/constants';
import { FileImageThumbnail } from 'src/components/FlatAppearance/components/FileImageThumbnail';

const SVG_SIZE = 20;

interface ThreadMessageBodyProps {
  message: Message;
}

export const ThreadMessageBody = ({ message }: ThreadMessageBodyProps) => {
  const { message_type, payload, is_final_answer, role } = message;

  const shouldStreaming = !is_final_answer && role !== ConversationRole.USER;

  const isContentWithFile =
    role === ConversationRole.USER &&
    message.content.length > 0 &&
    !!message.content.match(FILE_REGEX) &&
    (message.content.match(FILE_REGEX) || []).length >= 3;

  const isContentWithEditFile =
    role === ConversationRole.USER &&
    message.content.length > 0 &&
    !!message.content.match(EDIT_FILE_REGEX) &&
    (message.content.match(EDIT_FILE_REGEX) || []).length >= 2;

  const handleFileClick = () => {
    // todo
  };

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return <FilePdf size={SVG_SIZE} />;
      case 'txt':
        return <FileTxt size={SVG_SIZE} />;
      case 'docx':
        return <FileDoc size={SVG_SIZE} />;
      case 'csv':
        return <FileCsv size={SVG_SIZE} />;
      case 'xls':
        return <FileXls size={SVG_SIZE} />;
      default:
        return <FileText size={SVG_SIZE} />;
    }
  };

  const renderMessageContent = () => {
    if (isContentWithFile) {
      const filename =
        message.content.match(FILE_REGEX) !== null
          ? (message.content.match(FILE_REGEX) || [])[2].replaceAll('"', '')
          : '';
      const originalFilename =
        message.content.match(FILE_REGEX) !== null
          ? (message.content.match(FILE_REGEX) || [])[1].replaceAll('"', '')
          : '';

      const extention = originalFilename.split('.').pop();
      const isImage =
        extention === 'png' || extention === 'jpg' || extention === 'jpeg';

      const newText = message.content.replace(FILE_REGEX, '');
      return (
        <div className="nj-thread-message--content-with-file-wrapper">
          <CollapsibleTitle>
            <ThreadMessageContent
              content={newText}
              isStreaming={shouldStreaming}
            />
          </CollapsibleTitle>
          {isImage ? (
            <FileImageThumbnail fileName={filename} />
          ) : (
            !!originalFilename && (
              <button
                type="button"
                onClick={handleFileClick}
                className=" nj-thread-message--content-with-file-wrapper--file-label"
              >
                <div className="nj-thread-message--content-with-file-wrapper--file-label-text">
                  {renderExceptionIcon(extention || '')}
                  <span className="nj-thread-message--content-with-file-wrapper--file-name ellipsis">
                    {originalFilename}
                  </span>
                </div>
              </button>
            )
          )}
        </div>
      );
    }
    if (isContentWithEditFile) {
      const url =
        message.content.match(EDIT_FILE_REGEX) !== null
          ? (message.content.match(EDIT_FILE_REGEX) || [])[1]
          : '';
      const editedFilename =
        message.content.match(EDIT_FILE_REGEX) !== null
          ? (message.content.match(EDIT_FILE_REGEX) || [])[1].split('/').pop()
          : '';

      const newText = message.content.replace(EDIT_FILE_REGEX, '');
      return (
        <div className="nj-thread-message--content-with-file-wrapper">
          <CollapsibleTitle>
            <ThreadMessageContent
              content={newText}
              isStreaming={shouldStreaming}
            />
          </CollapsibleTitle>
          <FileImageThumbnail
            fileName={editedFilename || 'Edited file'}
            url={url}
          />
        </div>
      );
    }
    return (
      <CollapsibleTitle>
        <ThreadMessageContent
          content={message.content}
          isStreaming={shouldStreaming}
        />
      </CollapsibleTitle>
    );
  };

  const getCardToDisplay = () => {
    switch (message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ResearchTaskCreationCard}>
            <ThreadResearchCard
              researchCard={(payload as ResearchTaskCreationCard).research_card}
              // TODO(olha): this is a workaround! needs to change condition and moves to child components to avoid chain of props!!!
              isStreaming={shouldStreaming}
              originalQuery={message.clean_query}
            />
          </ThreadCombinedCard>
        );
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as SchedulerCreationCard}>
            <ThreadScheduleCard
              calendarCard={(payload as SchedulerCreationCard).calendar_card}
              details={(payload as SchedulerCreationCard).details}
              //TODO(olha): add prop from BE
              summary=""
            />
          </ThreadCombinedCard>
        );
      case MessageType.CODE_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ResearchTaskCreationCard}>
            <ThreadCodeCard
              codeCard={(payload as CodeTaskCreationCard).code_card}
              isStreaming={shouldStreaming}
            />
          </ThreadCombinedCard>
        );
      case MessageType.ERROR_MESSAGE:
        return (
          <ThreadErrorMessageContent
            content={message.content}
            errorCode={(payload as ErrorPayload).error_code}
            unsupportedSkills={(payload as ErrorPayload).unsupported_skills}
          />
        );
      case MessageType.CHAT_CARD:
        if (!payload) {
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ChitChatCard}>
            <ThreadChitChatCard
              chitChatCard={payload as ChitChatCard}
              isStreaming={shouldStreaming}
              originalQuery={message.clean_query}
            />
          </ThreadCombinedCard>
        );
      case MessageType.IMAGE_CARD:
        if (!payload) {
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ImageCard}>
            <ThreadImageGenerationCard imageCard={payload as ImageCard} />
          </ThreadCombinedCard>
        );
      default:
        return renderMessageContent();
    }
  };

  return (
    <DebugMessageWrapper message={message}>
      {getCardToDisplay()}
    </DebugMessageWrapper>
  );
};
