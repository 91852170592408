
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import styles from './Profile.module.scss';
import { useGetUserPhoto, useSession } from 'src/hooks';
import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useUserData } from 'src/hooks/useUserData';

interface ProfileFormData {
  first_name?: string;
  last_name?: string;
  department_of_team?: string;
  job_title?: string;
}

export const Profile = () => {
  const {
    appUser: { email, user_id, ...restUserInfo },
  } = useSession();
  const { photoUrl } = useGetUserPhoto();

  const [img, setImg] = useState('');
  const [errorTooltip, setErrorTooltip] = useState<string | null>(null);

  const { updateUserData } = useUserData();

  useEffect(() => {
    if (photoUrl) {
      setImg(photoUrl);
    }
  }, [photoUrl]);

  const handleChangeUserInfo = (
    name: 'first_name' | 'last_name',
    value: string,
  ) => {
    const initValue = restUserInfo[name] || '';
    if (value !== '') {
      if (value !== initValue) {
        updateUserData({ [name]: value });
      }
    } else {
      setErrorTooltip(name);
    }
  };

  const [trigger] = useLazyGetUserByIdQuery();

  const methods = useForm<ProfileFormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;
      return {
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        job_title:
          data?.settings?.profile_setting?.role_information?.job_title || '',
        department_of_team:
          data?.settings?.profile_setting?.role_information
            ?.department_of_team || '',
      };
    },
  });

  return (
    <FormProvider<ProfileFormData> methods={methods}>
      <ManageAccountContentPage title="Profile" subtitle='Manage your Ninja profile'>
        <div className={styles.root}>

          <section className={styles.section}>
            <h4 className={styles.title}>Your photo</h4>
            <h5 className={styles.subtitle}>
              JPEG, PNG, Max. file size: 5 MB
            </h5>

            <img
              referrerPolicy="no-referrer"
              src={img}
              alt="User Avatar"
              className={styles.userAvatar}
              width="96"
              height="96"
            />

            <hr className={styles.divider} />
          </section>

          <section className={styles.section}>

            <h4 className={styles.title}>Contact information</h4>
            <h5 className={styles.subtitle}>
              Enter your contact information so Ninja can use it when doing outreach.
            </h5>

            <div className={styles.group}>
              <div className={styles.field}>
                <label className={styles.label}>
                  First name
                  <span className={styles.rule}>Required</span>
                </label>
                <FormInput
                  name="first_name"
                  type="text"
                  placeholder="First name (e,g. Adam)"
                  onFocus={() => setErrorTooltip(null)}
                  onBlurHandler={(value) => handleChangeUserInfo('first_name', value)}
                  errorMessage={
                    errorTooltip === 'first_name' ? 'First name is required' : undefined
                  }
                />
              </div>

              <div className={styles.field}>
                <label className={styles.label}>
                  Last name
                  <span className={styles.rule}>Required</span>
                </label>
                <FormInput
                  name="last_name"
                  type="text"
                  placeholder="Last name (e,g. Smith)"
                  onFocus={() => setErrorTooltip(null)}
                  onBlurHandler={(value) => handleChangeUserInfo('last_name', value)}
                  errorMessage={
                    errorTooltip === 'last_name' ? 'Last name is required' : undefined
                  }
                />
              </div>
            </div>

            <div className={styles.group}>
              <div className={`${styles.field} ${styles.full}`}>
                <label className={styles.label}>Email</label>
                <input
                  disabled
                  value={email}
                />
              </div>
            </div>

            <hr className={styles.divider} />
          </section>

          <section className={styles.section}>
            <h4 className={styles.title}>Role information</h4>
            <h5 className={styles.subtitle}>
              Enter your role information.
            </h5>

            <div className={styles.group}>
              <div className={`${styles.field} ${styles.full}`}>
                <label className={styles.label}>
                  Job title
                  <span className={styles.rule}>Optional</span>
                </label>
                <FormInput
                  name="job_title"
                  type="text"
                  placeholder="Your job title (e,g. Account manager)"
                  onBlurHandler={(value) =>
                    updateUserData({
                      settings: {
                        ...restUserInfo.settings,
                        profile_setting: {
                          ...restUserInfo.settings?.profile_setting,
                          role_information: {
                            ...restUserInfo.settings?.profile_setting?.role_information,
                            job_title: value,
                          },
                        },
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className={styles.group}>
              <div className={`${styles.field} ${styles.full}`}>
                <label className={styles.label}>
                  Department of team
                  <span className={styles.rule}>Optional</span>
                </label>
                <FormInput
                  name="department_of_team"
                  type="text"
                  placeholder="Your team (e,g. Sales)"
                  onBlurHandler={(value) =>
                    updateUserData({
                      settings: {
                        ...restUserInfo.settings,
                        profile_setting: {
                          ...restUserInfo.settings?.profile_setting,
                          role_information: {
                            ...restUserInfo.settings?.profile_setting?.role_information,
                            department_of_team: value,
                          },
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
          </section>
        </div>
      </ManageAccountContentPage>
    </FormProvider>
  );
};
