import { Link } from 'react-router-dom';
import { Pulse } from '@phosphor-icons/react';
import { AppRoutes, UserSettingsNestedRoutes } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import styles from './AccountActivityLink.module.scss';
import { useSession } from 'src/hooks';

export const AccountActivityLink = () => {
  const { isProTier } = useSession();

  if (!isProTier) {
    return null
  }

  return (
    <Link
      to={`${AppRoutes.MANAGE_ACCOUNT}/${UserSettingsNestedRoutes.ACTIVITY}`}
      className={styles.root}
    >
      <Pulse size={SVG_SIZE_M} />
      Account activity
    </Link>
  );
};
