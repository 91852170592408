import { createApi } from '@reduxjs/toolkit/query/react';
import { baseContentQuery } from './config';

export enum ContentTags {
  UploadFileURL = 'UploadFileURL',
  GetFileURL = 'GetFileURL',
}

export const contentApi = createApi({
  reducerPath: 'contentApi',
  tagTypes: [ContentTags.UploadFileURL, ContentTags.GetFileURL],
  baseQuery: baseContentQuery,
  endpoints: (builder) => ({
    getURLForFileUpload: builder.query<
      {
        filename: string;
        form_data: {
          url: string;
          fields: string[];
        };
        original_filename: string;
      },
      { user_id: string; file_name: string }
    >({
      query: ({ user_id, file_name }) =>
        `/users/${user_id}/upload-form/${file_name}`,
      providesTags: () => [ContentTags.UploadFileURL],
    }),
    getFileURL: builder.query<
      {
        url: string;
      },
      { user_id: string; file_name: string }
    >({
      query: ({ user_id, file_name }) =>
        `/users/${user_id}/content-url/${file_name}`,
      providesTags: () => [ContentTags.GetFileURL],
    }),
    deleteFile: builder.mutation<void, { user_id: string; file_name: string }>({
      query: ({ user_id, file_name }) => ({
        url: `/users/${user_id}/content/${file_name}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetURLForFileUploadQuery,
  useDeleteFileMutation,
  useGetFileURLQuery,
} = contentApi;
