import { Header } from 'src/pages/AuthPages/components/Header';
import { SignupHeader } from 'src/pages/AuthPages/components/SignupHeader';
import atlasSmile from 'src/images/login/atlas_smile.png';
import { Link } from 'react-router-dom';
import { useBreakpoint } from 'src/hooks';
// import { toast } from 'react-toastify';
// import { ServiceError } from '@aws-amplify/core/src/types/errors';
// import { useSearchParams } from 'react-router-dom';
// import { resendSignUpCode } from 'aws-amplify/auth';

export const VerifiedPage = () => {
  const { isMobileOrTablet } = useBreakpoint();
  // const [searchParams] = useSearchParams();
  // const username = searchParams.get('email');

  // const handleResendLink = async () => {
  //   try {
  //     if (username) {
  //       await resendSignUpCode({ username });
  //       toast('Verification link resent');
  //     }
  //   } catch (error) {
  //     toast.error(
  //       error
  //         ? (error as ServiceError).message
  //         : 'Something went wrong, try again later',
  //     );
  //   }
  // };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<SignupHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-blue-gradient">Meet Ninja</h1>
        <h2 className="nj-auth-header-small">Your Autonomous AI Agent</h2>
        <h3 className="nj-auth-header-secondary">Verify your email</h3>
        <div className="nj-auth-verified-block">
          <p className="nj-auth-verified-text">
            A verification email has been sent to your email address. The
            verification email will expire after 24 hours.
          </p>
          {/*<button*/}
          {/*  className="nj-auth-form--submit-button"*/}
          {/*  onClick={handleResendLink}*/}
          {/*>*/}
          {/*  Resend verification link*/}
          {/*</button>*/}
        </div>
        <Link to="/forgot-password" className="nj-auth-forgot-password-link">
          Try again
        </Link>
      </main>
      {!isMobileOrTablet && (
        <img
          className="nj-auth-background-image"
          src={atlasSmile}
          alt="Ninja"
        />
      )}
    </div>
  );
};
