import { Paperclip } from '@phosphor-icons/react';
import { AttachmentFileInputId } from 'src/constants';
import { ChangeEvent, lazy, Suspense, useContext } from 'react';
import { useAttachFile, useSession } from 'src/hooks';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const AddAttachmentButton = () => {
  const { addFile } = useAttachFile();
  const { threadInputBoxFile } = useContext<ThreadInputBoxContextType>(
    ThreadInputBoxContext,
  );
  const { isOpenTier } = useSession();

  const handleAttachClick = () => {
    document.getElementById(AttachmentFileInputId)?.click();
  };

  const handleFileSelect = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement)?.files;
    const file = files ? files[0] : null;
    if (file) {
      await addFile(file);
    }
  };

  const isDisabled = !!threadInputBoxFile || isOpenTier;

  return (
    <div>
      <button
        className="nj-thread-input-box--attach-button"
        onClick={handleAttachClick}
        disabled={isDisabled}
        data-tooltip-id="attachment-tooltip"
        data-tooltip-place="top-start"
        data-tooltip-hidden={!isDisabled}
      >
        <Paperclip size={24} />
      </button>
      <input
        id="attachFile"
        type="file"
        accept=".txt,.docx,.pdf,.rtf,.csv,.tsv,.xlsx,.png,.jpg,.jpeg"
        onChange={handleFileSelect}
        className="nj-thread-input-box--attach-input"
      />
      <Suspense>
        <LazyReactTooltip
          id="attachment-tooltip"
          noArrow={true}
          className="nj-attachment-tooltip"
        >
          {isOpenTier
            ? 'Attachment requires a signed-in account.'
            : 'Only one attachment is allowed per request'}
        </LazyReactTooltip>
      </Suspense>
    </div>
  );
};
