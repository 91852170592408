import { useContext } from 'react';
import classNames from 'classnames';
import AvatarModeContext from 'src/contexts/AvatarModeContext';

import { AvatarSettingsMenu } from '../AvatarSettingsMenu';
import { SVG_SIZE_S, SVG_SIZE_L } from 'src/constants';
import { CCButton } from '../CCButton';
import { MicrophoneButton } from '../MicrophoneButton';
import { FullScreenButton } from '../FullScreenButton';
import { AvatarSelectorButton } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorButton';

type AvatarMultiButtonControlProps = {
  isVideoFloating?: boolean;
  onFullscreen: (val: boolean) => void;
};

export const AvatarMultiButtonControl = ({
  isVideoFloating = false,
  onFullscreen,
}: AvatarMultiButtonControlProps) => {
  const { expandedMeta: isInFullscreen } = useContext(AvatarModeContext);

  const iconSize = isVideoFloating ? SVG_SIZE_S : SVG_SIZE_L;

  return (
    <div
      className={classNames('nj-meta-human--multi-button-control', {
        floating: isVideoFloating,
      })}
    >
      {!isVideoFloating && <CCButton iconSize={iconSize} />}

      <MicrophoneButton iconSize={iconSize} />

      <FullScreenButton
        iconSize={iconSize}
        isVideoFloating={isVideoFloating}
        onFullscreen={onFullscreen}
      />

      <AvatarSelectorButton iconSize={iconSize} inVideoSettings />

      {!(isVideoFloating && isInFullscreen) && (
        <AvatarSettingsMenu isSmall={isVideoFloating} />
      )}
    </div>
  );
};
