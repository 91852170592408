import {
  useBreakpoint,
  useGoogleResourcesAccess,
  useSession,
  useUserData,
} from 'src/hooks';
import mobileBackground from 'src/images/backgrounds/maintenance-mobile-bg.png';
import desktopBackground from 'src/images/backgrounds/maintenance-desktop-bg.png';
import { Modal } from 'react-responsive-modal';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  DEFAULT_ACCEPTABLE_USE_POLICY,
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';
import { IS_SIGN_UP_REDIRECT, sendGTMEvent } from 'src/utils';
import { useLogout } from 'src/hooks/useLogout';
import { GTMEvent } from 'src/types';
import SessionContext from 'src/contexts/SessionContext';
import ninjaWithSkills from 'src/images/login/ninja_with_skills.png';
import { PLAN_OPTIONS, SELECTED_PLAN } from 'src/constants';
import log from 'src/utils/logger';
import { useUpgradeSubscriptionMutation } from 'src/store/services';

type TermsOfServiceScreenProps = {
  onWait: (value: boolean) => void;
};

export const TermsOfServiceScreen = ({ onWait }: TermsOfServiceScreenProps) => {
  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);
  const { isMobile } = useBreakpoint();
  const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { appUser, isFreeTier } = useSession();
  const { deleteCognitoUser } = useLogout();
  const { updateUserData } = useUserData();
  const [upgradeSubscription] = useUpgradeSubscriptionMutation();

  useEffect(() => {
    localStorage.setItem(IS_SIGN_UP_REDIRECT, 'true');

    sendGTMEvent(GTMEvent.CONSENT_FORM_VIEW);
  }, []);

  const {
    revokeGoogleAccess,
    canAccessContacts,
    canAccessDirectory,
    canAccessCalendar,
  } = useGoogleResourcesAccess();

  const handleCancel = async () => {
    sendGTMEvent(GTMEvent.CONSENT_FORM_CANCEL);

    if (canAccessCalendar || canAccessDirectory || canAccessContacts) {
      await revokeGoogleAccess(appUser.user_id);
    }
    deleteCognitoUser();
  };

  const updateUserInfo = async () => {
    await updateUserData(
      {
        consented: true,
      },
      false,
    );
    localStorage.removeItem(IS_SIGN_UP_REDIRECT);
  };

  const handleContinue = async () => {
    sendGTMEvent(GTMEvent.CONSENT_FORM_CONFIRM);

    const plan = localStorage.getItem(SELECTED_PLAN);

    if (plan && isFreeTier) {
      setIsLoading(true);
      onWait(true);
      const selectedPlan = PLAN_OPTIONS.find(
        (item) => item.lookup_key === plan,
      );

      if (selectedPlan) {
        const { lookup_key, promotion_code } = selectedPlan;

        try {
          const result = await upgradeSubscription({
            lookup_key,
            user_id: appUser.user_id,
            promotion_code,
          }).unwrap();

          const redirectUrl =
            result.checkout_session?.url || result.billing_portal_session?.url;

          localStorage.removeItem(SELECTED_PLAN);

          await updateUserInfo();

          setIsLoading(true);

          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
          onWait(false);
        } catch (error) {
          log.error(error);
          setIsLoading(true);
          onWait(false);
          localStorage.removeItem(SELECTED_PLAN);
        }
      }
    }

    await updateUserInfo();

    if (isFreeTier) {
      onToggleVisibleUpgradeModal(true);
    }
  };

  return (
    <div
      className="nj-maintenance-screen"
      style={{
        backgroundImage: isMobile
          ? `url(${mobileBackground})`
          : `url(${desktopBackground})`,
        backgroundSize: 'cover',
      }}
    >
      <Modal
        open={true}
        onClose={
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        }
        container={document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay nj-modal-maintenance-overlay',
          modal: 'nj-modal--access nj-terms-modal',
        }}
        center
        closeOnOverlayClick={false}
        showCloseIcon={false}
        blockScroll={false}
      >
        <div className="nj-terms-modal-wrapper">
          <header className="nj-terms-modal-header">
            <img
              className="nj-terms-modal-header-background-image"
              src={ninjaWithSkills}
              alt="Ninja with skills"
            />
          </header>
          <div className="nj-terms-modal-content-wrapper">
            <div className="nj-terms-modal-content">
              <div className="nj-terms-modal-title-wrapper">
                <h2 className="nj-terms-modal-title">
                  Welcome {appUser?.first_name || ''}!
                </h2>
                <h4 className="nj-terms-modal-subtitle">
                  I’m Ninja, your Personal AI Agent. Behind the scenes we have
                  series of AI agents that work for you, take a quick tour to
                  learn about their capabilities.
                </h4>
              </div>
              <div className="nj-terms-modal-body-wrapper">
                <div className="nj-terms-modal-content">
                  <div className="nj-terms-modal-text-wrapper">
                    <h5 className="nj-terms-modal-text-title">
                      Terms of Service
                    </h5>
                    <p className="nj-terms-modal-text">
                      Your use of Ninja (powered by Ninjatech AI) are subject to
                      the
                      <a
                        href={DEFAULT_TERMS_OF_SERVICE_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="nj-terms-link"
                      >
                        &nbsp;Ninjatech AI's Terms of Service&nbsp;
                      </a>
                      and
                      <a
                        href={DEFAULT_ACCEPTABLE_USE_POLICY}
                        target="_blank"
                        rel="noreferrer"
                        className="nj-terms-link"
                      >
                        &nbsp;Acceptable Use Policy&nbsp;
                      </a>
                      .
                    </p>
                    <div>
                      <h6 className="nj-terms-modal-title-secondary">
                        Things to Know
                      </h6>
                      <ul className="nj-terms-modal-list">
                        <li>
                          Don't submit sensitive personal information or
                          confidential information to Ninja. Don’t submit more
                          personal information to Ninja than is requested or
                          required for Ninja to perform the Services. To help
                          with quality, safety, and to improve our products,
                          human reviewers and 3rd party tools may read,
                          annotate, and process your inputs and output.
                          Ninjatech AI takes steps to protect your privacy as
                          part of this process, but during the Beta period, our
                          processes may have flaws.
                        </li>
                        <li>
                          The AI Agent technology powering Ninja is experimental
                          technology and may sometimes provide inaccurate or
                          inappropriate information that doesn't represent
                          Ninjatech AI's views.
                        </li>
                        <li>
                          Don't rely on responses as medical, legal, financial,
                          or other professional advice.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="nj-checkbox">
                  <input
                    className="nj-checkbox--input"
                    id="privacy_policy"
                    type="checkbox"
                    checked={isPrivacyPolicyAgreed}
                    onChange={(data: ChangeEvent<HTMLInputElement>) => {
                      setIsPrivacyPolicyAgreed(!isPrivacyPolicyAgreed);
                    }}
                  />
                  <label
                    className="nj-checkbox--label"
                    htmlFor="privacy_policy"
                  >
                    <span className="nj-checkbox--label-text">
                      I consent to
                      <a
                        href={DEFAULT_TERMS_OF_SERVICE_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="nj-terms-link"
                      >
                        &nbsp;Ninjatech AI's Terms of Service&nbsp;
                      </a>
                      and
                      <a
                        href={DEFAULT_ACCEPTABLE_USE_POLICY}
                        target="_blank"
                        rel="noreferrer"
                        className="nj-terms-link"
                      >
                        &nbsp;Acceptable Use Policy
                      </a>
                      , and acknowledge that I have read
                      <a
                        href={DEFAULT_PRIVACY_POLICY_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="nj-terms-link"
                      >
                        &nbsp;Ninjatech AI's Privacy Policy
                      </a>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="nj-terms-modal-buttons-row">
              <button
                className="nj-terms-modal-button-cancel"
                onClick={handleCancel}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                className="nj-terms-modal-button-continue"
                onClick={handleContinue}
                disabled={!isPrivacyPolicyAgreed || isLoading}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
