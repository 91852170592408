import {
  useEffect,
  useRef,
  useState,
  Fragment,
  useContext,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from 'react';
import classNames from 'classnames';
import ForwardRefContext, {
  ThreadMessageListHandlers,
} from 'src/contexts/ForwardRefContext';
import { ChatMode, Message } from 'src/types';
import {
  addEmptyWaitingMessage,
  prepareMessagesToRender,
  formatByDayjsMonthDay,
} from 'src/utils';
import {
  useSession,
  useAvatarPreferences,
  useScrollToBottomThread,
  useThreads,
  useTranslateMessages,
  useAutoScrollByResize,
  useTimeZone,
  useTheme,
} from 'src/hooks';
import { ThreadMessage } from '../ThreadMessage';
import { ThreadDividerLine } from '../ThreadDividerLine';
import { NinjaLoader } from 'src/components/Loading';

export const ThreadMessageList = forwardRef<ThreadMessageListHandlers>(() => {
  const endOfMessagesRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { threadMessageListAnchorRef, threadMessageListRef } =
    useContext(ForwardRefContext);

  const { avatarCCLocale, avatarAudioLocale } = useAvatarPreferences();
  const { chatMessages, ignoreMessages, isFetchingConversation } = useThreads();
  const { chatMode } = useSession();
  const { isSettingsPanelExpanded } = useTheme();

  const { userTimeZone } = useTimeZone();

  useScrollToBottomThread(endOfMessagesRef);

  const [translatedMessages, setTranslatedMessages] = useState<Message[]>([]);

  const { onTranslateMessages } = useTranslateMessages();

  const handleScrollToBottom = (delay = 1000) => {
    setTimeout(() => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, delay);
  };

  useImperativeHandle(threadMessageListAnchorRef, () => ({
    onScrollToBottomMessageList: handleScrollToBottom,
  }));

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  // TODO(olha): moved from MultiConversationsMessagesList. Try to call translation by clicking to the avatar mode
  useEffect(() => {
    if (isAvatarMode) {
      const translateMessages = async () => {
        const result = await onTranslateMessages();
        setTranslatedMessages(result);
      };

      translateMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chatMessages,
    avatarCCLocale,
    avatarAudioLocale,
    isAvatarMode,
    onTranslateMessages,
  ]);

  const shouldShowLoading = useMemo(
    () => isFetchingConversation && chatMessages.length === 0,
    [isFetchingConversation, chatMessages],
  );

  // attn(olha): we removed useMemo to avoid some cache issues.
  const messagesToRender = prepareMessagesToRender(
    !isAvatarMode
      ? addEmptyWaitingMessage(chatMessages, ignoreMessages)
      : translatedMessages,
  );

  useAutoScrollByResize({
    containerRef,
    messageListLength: messagesToRender.length,
  });

  if (chatMessages.length === 0 && !isFetchingConversation) {
    return null;
  }

  return shouldShowLoading ? (
    <NinjaLoader content="Ninja is loading the content" />
  ) : (
    <div
      ref={threadMessageListRef}
      className={classNames('nj-thread-message-list', {
        'avatar-mode': isAvatarMode,
        'is-settings-panel-expanded': isSettingsPanelExpanded,
        'with-min-height': chatMessages.length !== 0,
      })}
    >
      <div ref={containerRef} className="nj-thread-message-list-wrapper">
        {messagesToRender.map(
          ({ showDate, showSkillDivider, anchorId, ...message }, index) => (
            // TODO(olha): I have to return the index because as soon as a new message is added to the thread, it does not contain an ID. ID arrives a little later, from the combus. And while there is no ID, there are errors in the console. We need to investigate this point more deeply during refactoring
            <Fragment key={message.message_id || index}>
              {showDate && message.timestamp && (
                <p className="nj-thread-message-list--date-line">
                  {formatByDayjsMonthDay(message.timestamp, userTimeZone)}
                </p>
              )}

              <ThreadDividerLine anchorId={anchorId} />

              <ThreadMessage
                message={message}
                canShowProgress={index === messagesToRender.length - 1}
              />
            </Fragment>
          ),
        )}

        <span
          // anchor for scrolling to the bottom
          className="nj-thread-message-list--anchor"
          ref={endOfMessagesRef}
        />
      </div>
    </div>
  );
});
