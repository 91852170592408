import { useRef, useEffect } from 'react';

export const usePortal = (rootId: string) => {
  const portalRoot = document.getElementById(rootId);
  const elRef = useRef<HTMLDivElement | null>(null);

  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  useEffect(() => {
    if (portalRoot && elRef.current) {
      portalRoot.appendChild(elRef.current);
    }
    return () => {
      if (portalRoot && elRef.current) {
        portalRoot.removeChild(elRef.current);
      }
    };
  }, [portalRoot]);

  if (!portalRoot) {
    return null;
  }

  return elRef.current;
};
