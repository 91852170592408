import { useRef } from 'react';
import styles from './Tooltip.module.scss';
import { X } from '@phosphor-icons/react';
import { useTheme, useBreakpoint } from 'src/hooks';
import { useVisible, useThreads } from 'src/hooks';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface TooltipProps {
  children: React.ReactNode;
}

export const Tooltip = ({ children }: TooltipProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const SVG_SIZE = 24;
  const { isVisible, handleVisibilityRemove } = useVisible(true);

  const { isTasksListExpanded } = useTheme();
  const { shouldShowPillars } = useThreads();
  const { isMobile } = useBreakpoint();

  const handleClick = () => {
    handleVisibilityRemove();
  };

  const display = shouldShowPillars && !isTasksListExpanded && isVisible;

  return (
    <CSSTransition
      in={display}
      timeout={ANIMATION_TIMEOUT}
      classNames={isMobile ? 'nj-animate-vertical-appearance' : ''}
      unmountOnExit
      appear={display}
      nodeRef={containerRef}
    >
      <div ref={containerRef} className={styles.root}>
        <div className={styles.content}>
          <X size={SVG_SIZE} className={styles.close} onClick={handleClick} />
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>Try Ninja Pro for 90% off</h4>
            <span className={styles.tag}>Limited time</span>
          </div>
          <p className={styles.description}>
            Deep research, multi-party meeting negotiations, access to 20+
            external LLMs and video chat with Ninja
          </p>

          {children}
        </div>
      </div>
    </CSSTransition>
  );
};
