import { useContext, useMemo, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { AvatarSelectorContent } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent';
import { PortalWrapper } from 'src/components/PortalWrapper';
import { useOutsideClick } from 'src/hooks';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const AvatarSelectorTooltip = () => {
  const { expandedMeta } = useContext(AvatarModeContext);
  const { isShowAvatarSelect, onToggleAvatarSelect } =
    useContext(SessionContext);

  const {
    avatarsSelectorRef,
    avatarsSelectorButtonRef,
    avatarsSelectorVideoButtonRef,
  } = useContext(ForwardRefContext);

  const Wrapper = useMemo(
    () => (expandedMeta ? Fragment : PortalWrapper),
    [expandedMeta],
  );

  const handleCloseSelector = () => {
    onToggleAvatarSelect(false);
  };

  useOutsideClick(
    avatarsSelectorRef,
    handleCloseSelector,
    avatarsSelectorButtonRef,
    avatarsSelectorVideoButtonRef,
  );

  return (
    <Wrapper>
      <Suspense>
        <LazyReactTooltip
          anchorSelect="#avatar-select"
          clickable
          noArrow
          openOnClick
          className="nj-avatar-select-tooltip"
          isOpen={isShowAvatarSelect}
          opacity={1}
          place="bottom-start"
          offset={10}
        >
          <AvatarSelectorContent />
        </LazyReactTooltip>
      </Suspense>
    </Wrapper>
  );
};
