import cn from 'classnames';
import { AvatarSessionNotice } from '../AvatarSessionNotice';
import { AvatarMultiButtonControl } from '../AvatarMultiButtonsControl';
import { MuteButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MuteButton';
import styles from './AvatarFrameButtonsLayout.module.scss';
import { LeaveVideoButton } from '../LeaveVideoButton';

type AvatarFrameButtonsLayoutProps = {
  isVideoFloating?: boolean;
  onFullscreen: (val: boolean) => void;
};

export const AvatarFrameButtonsLayout = ({
  isVideoFloating = false,
  onFullscreen,
}: AvatarFrameButtonsLayoutProps) => {
  return (
    <>
      {isVideoFloating && <LeaveVideoButton />}

      <div
        className={cn(styles.actionButtonWrapper, {
          [styles.small]: isVideoFloating,
        })}
      >
        <MuteButton showLabel isSmall={isVideoFloating} />

        <AvatarMultiButtonControl
          isVideoFloating={isVideoFloating}
          onFullscreen={onFullscreen}
        />
      </div>

      <AvatarSessionNotice />
    </>
  );
};
