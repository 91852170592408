import { useEffect, useContext } from 'react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { exitFullscreen } from 'src/utils';

export const useFullScreen = () => {
  const { expandedMeta, toggleExpandedMeta, toggleTranscript } =
    useContext(AvatarModeContext);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreenEnabled = Boolean(document.fullscreenElement);
      if (!isFullscreenEnabled && expandedMeta) {
        exitFullscreen();
        toggleTranscript(true);
        toggleExpandedMeta(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [expandedMeta, toggleExpandedMeta, toggleTranscript]);
};
