import { useContext } from 'react';
import cn from 'classnames';
import { X, VideoCameraSlash } from '@phosphor-icons/react';
import { ChatMode } from 'src/types';
import { useSession } from 'src/hooks';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { SVG_SIZE_S } from 'src/constants';
import styles from './LeaveVideoButton.module.scss';
import { exitFullscreen, interruptMetahuman } from 'src/utils';

export const LeaveVideoButton = () => {
  const {
    clearShowAvatarQueue,
    expandedMeta: isInFullscreen,
    toggleExpandedMeta,
    toggleTranscript,
    stopAvatarSession,
  } = useContext(AvatarModeContext);
  const { appUser, updateAvatarQueue, updateChatMode } = useSession();

  const handleClick = () => {
    if (isInFullscreen) {
      exitFullscreen();
      toggleTranscript(true);
      toggleExpandedMeta(false);
    }
    interruptMetahuman(appUser.user_id);
    stopAvatarSession();
    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
  };

  return (
    <button
      className={cn(styles.root, {
        [styles.warning]: isInFullscreen,
      })}
      onClick={handleClick}
    >
      {isInFullscreen ? (
        <VideoCameraSlash weight="fill" size={SVG_SIZE_S} />
      ) : (
        <X size={SVG_SIZE_S} />
      )}
    </button>
  );
};
