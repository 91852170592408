import { sanitize } from 'dompurify';
import { decodeHTML } from 'entities';

/**
 * removeReferencesInBrackets() removes references in brackets: [3][7].
 * @param str string
 * @returns string
 */
export const removeReferencesInBrackets = (content: string) => {
  return content.replace(/\[\d\]/g, '').replace(/,{2,}/gi, ',');
};

export const sanitizeOutgoingContent = (content: string) => {
  return sanitize(content);
};

export const sanitizeIncomingContent = (content: string) => {
  const clearedContent = removeReferencesInBrackets(content);
  const sanitizedContent = sanitize(clearedContent);

  return decodeHTML(sanitizedContent);
};
