import { useSession } from 'src/hooks';
import { useGetFileURLQuery } from 'src/store/services';
import { Spinner } from 'src/components/Loading';
import { SVG_SIZE_M } from 'src/constants';
import { Warning } from '@phosphor-icons/react';

type FileImageThumbnailProp = {
  fileName: string;
  url?: string;
};
export const FileImageThumbnail = ({
  fileName,
  url,
}: FileImageThumbnailProp) => {
  const { appUser } = useSession();
  const { data, isError } = useGetFileURLQuery(
    { user_id: appUser.user_id, file_name: fileName },
    {
      skip: !fileName || !appUser.user_id || !!url,
    },
  );

  if (!data?.url && !url) {
    return (
      <div className="nj-thread-input-box--file-image-wrapper">
        {isError ? (
          <Warning size={SVG_SIZE_M} weight="fill" className="error-icon" />
        ) : (
          <Spinner size={SVG_SIZE_M} inline />
        )}
      </div>
    );
  }

  return (
    <img
      src={data?.url || url || ''}
      alt={fileName}
      className="nj-thread-input-box--file-image"
    />
  );
};
