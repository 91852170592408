import { useContext, useMemo, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowLineLeft } from '@phosphor-icons/react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { CommunicationStyle } from 'src/constants';
import { useAvatarPreferences, useTheme, useSession } from 'src/hooks';
import { AvatarImage } from 'src/components/AvatarImage';
import { AvatarSelectorButton } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorButton';
import styles from './AppNav.module.scss';
import { SVG_SIZE_M } from 'src/constants';

export const AppNav = () => {
  const { appUser } = useSession();
  const { toggleTasksListExpanded } = useTheme();

  const { expandedMeta: isInFullscreen } = useContext(AvatarModeContext);

  const { selectedCommunicationTone } = useAvatarPreferences();

  const selectedCommunicationStyle = appUser?.persona?.conversational_style
    ? CommunicationStyle[appUser.persona.conversational_style]
    : null;

  const currentSubtitle = useMemo(
    () =>
      `${selectedCommunicationStyle || ''}, ${selectedCommunicationTone?.title || ''}`,
    [selectedCommunicationTone, selectedCommunicationStyle],
  );

  const onCloseTasksList = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    toggleTasksListExpanded();
  };

  if (isInFullscreen) {
    return null;
  }

  return (
    <div className={styles.root}>
      <NavLink to="/">
        <AvatarImage />
      </NavLink>

      <div className={styles.content}>
        <h1 className={styles.title}>Ninja</h1>
        <p className={styles.subtitle}>{currentSubtitle}</p>
      </div>

      <AvatarSelectorButton />

      <button onClick={onCloseTasksList} className={styles.toggleButton}>
        <ArrowLineLeft size={SVG_SIZE_M} />
      </button>
    </div>
  );
};
