import { useSession } from 'src/hooks';
import { Tooltip } from './Tooltip';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';

export const PromoTooltip = () => {
  const { isProTier, isFreeTier, isOpenTier } = useSession();

  if (isProTier) {
    return null;
  }

  return (
    <Tooltip>
      {isFreeTier && <UpgradeButton />}
      {isOpenTier && <GuestAuthLinks />}
    </Tooltip>
  );
};
