import { SlidersHorizontal } from '@phosphor-icons/react';
import { type CommunicationToneID, Message } from 'src/types';
import { useEffect, useMemo, useRef, useState, lazy, Suspense } from 'react';
import {
  useAvatarPreferences,
  useOutsideClick,
  useSession,
  useSubmitUserInput,
  useThreads,
  useVisible,
} from 'src/hooks';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { PersonaSectionItemKey } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent';
import { CommunicationStyle } from 'src/constants';
import { ConversationalStyleSection } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent/components/ConversationalStyleSection';
import { ConversationalToneSection } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent/components/ConversationalToneSection';
import { Persona } from 'src/types/models/Persona';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

type RedoWithPersonaProps = {
  message: Message;
};

export const RedoWithPersona = ({ message }: RedoWithPersonaProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { appUser } = useSession();
  const { selectedCommunicationTone, communicationTonesOptions } =
    useAvatarPreferences();
  const { isSubmitHappened } = useThreads();
  const { onSubmitUserInput } = useSubmitUserInput();
  const {
    isVisible: isRedoWithPersonaVisible,
    handleVisibilityToggle: handleRedoWithPersonaVisibilityToggle,
    handleVisibilityRemove: handleRedoWithPersonaVisibilityRemove,
  } = useVisible();

  const userInput = message?.original_query || '';

  const [currentConversationalStyle, setCurrentConversationalStyle] = useState<
    number | number[]
  >(3);
  const [currentCommunicationTone, setCurrentCommunicationTone] = useState(
    selectedCommunicationTone,
  );

  useEffect(() => {
    if (!!appUser.persona?.conversational_style) {
      setCurrentConversationalStyle(appUser.persona?.conversational_style);
    }
  }, [appUser.persona?.conversational_style]);

  useEffect(() => {
    if (!!selectedCommunicationTone) {
      setCurrentCommunicationTone(selectedCommunicationTone);
    }
  }, [appUser.persona?.communication_tone_id, selectedCommunicationTone]);

  const messageIdValidPart = message.message_id?.slice(-6);

  useOutsideClick(containerRef, handleRedoWithPersonaVisibilityRemove);

  const handleChangeCommunicationTone = (value: string) => {
    const updatedValue = communicationTonesOptions.find(
      (item) => item.id === value,
    );
    setCurrentCommunicationTone(updatedValue);
  };

  const getAccordionItems = useMemo(() => {
    return [
      {
        key: PersonaSectionItemKey.style,
        title: 'Conversational style',
        valueSubtitle: CommunicationStyle[currentConversationalStyle as number],
        content: (
          <ConversationalStyleSection
            onChange={setCurrentConversationalStyle}
          />
        ),
      },
      {
        key: PersonaSectionItemKey.tone,
        title: 'Conversational tone',
        valueSubtitle: currentCommunicationTone?.title || '',
        content: (
          <ConversationalToneSection
            value={currentCommunicationTone}
            onChange={handleChangeCommunicationTone}
            isShowUpsellMessage={true}
          />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversationalStyle, currentCommunicationTone]);

  const handleRegenerateClick = () => {
    const persona = {
      ...appUser?.persona,
      communication_tone_id:
        currentCommunicationTone?.id as CommunicationToneID,
      conversational_style: currentConversationalStyle,
    };
    onSubmitUserInput(userInput, { persona: persona as Persona });
    handleRedoWithPersonaVisibilityRemove();
  };

  const handleOpenTooltip = () => {
    if (!isRedoWithPersonaVisible) {
      setCurrentCommunicationTone(selectedCommunicationTone);
    }
    handleRedoWithPersonaVisibilityToggle();
  };

  return (
    <div className="nj-message-footer--redo-with-persona" ref={containerRef}>
      <button
        onClick={handleOpenTooltip}
        id={`redo-with-persona-${messageIdValidPart}`}
        disabled={isSubmitHappened || !userInput}
      >
        <SlidersHorizontal size={20} />
      </button>

      {isRedoWithPersonaVisible && (
        <Suspense>
          <LazyReactTooltip
            anchorSelect={`#redo-with-persona-${messageIdValidPart}`}
            clickable
            noArrow
            openOnClick
            className="nj-redo-tooltip nj-redo-with-persona-tooltip"
            isOpen={isRedoWithPersonaVisible}
            opacity={1}
            place="top-start"
            offset={2}
          >
            <>
              <header className="nj-redo-tooltip-header">
                <span>Regenerate with different tone & verbosity</span>
                <button
                  className="nj-redo-with-persona-tooltip-regenerate-button"
                  onClick={handleRegenerateClick}
                >
                  Regenerate
                </button>
              </header>
              <div className="nj-accordion nj-accordion-persona">
                {getAccordionItems.map(
                  ({ key, title, content, valueSubtitle }) => (
                    <CollapsedItem
                      title={title}
                      content={content}
                      valueSubtitle={valueSubtitle}
                      key={key}
                    />
                  ),
                )}
              </div>
            </>
          </LazyReactTooltip>
        </Suspense>
      )}
    </div>
  );
};
