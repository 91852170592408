import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { VideoCamera, VideoCameraSlash } from '@phosphor-icons/react';
import cn from 'classnames';
import { useSession } from 'src/hooks';
import { ChatMode, GTMEvent } from 'src/types';
import { exitFullscreen, interruptMetahuman, sendGTMEvent } from 'src/utils';
import { SVG_SIZE_M } from 'src/constants';
import styles from './VideoCallButton.module.scss';

export const VideoCallButton = () => {
  const {
    expandedMeta: isInFullscreen,
    showAvatarQueueBanner,
    setShowAvatarQueueModal,
    setShowAvatarQueueBanner,
    clearShowAvatarQueue,
    stopAvatarSession,
    toggleTranscript,
    toggleExpandedMeta,
  } = useContext(AvatarModeContext);

  const { onToggleUpsellAvatarModal } = useContext(SessionContext);

  const {
    appUser,
    chatMode,
    avatarQueue = {},
    updateAvatarQueue,
    updateChatMode,
    isOpenTier,
  } = useSession();

  const handleClick = () => {
    // if it is guest user and have no access to video call
    if (!appUser?.settings?.features?.video_chat?.is_capable && isOpenTier) {
      onToggleUpsellAvatarModal();

      sendGTMEvent(GTMEvent.START_VIDEO_CLICK);
      return;
    }
    // if user is not in the queue
    if (!avatarQueue?.joinedAvatarQueue) {
      // We do not switch to ChatMode.AVATAR
      // any longer and instead have an intermediate
      // step within the queue modal.
      setShowAvatarQueueModal(true);
      updateAvatarQueue({ joinedAvatarQueue: true, available: false, step: 1 });

      sendGTMEvent(GTMEvent.START_VIDEO_CLICK);
    } else {
      // when user is in the queue & top banner is shown
      // switch to the modal view instead of banner
      if (showAvatarQueueBanner) {
        setShowAvatarQueueBanner(false);
        setShowAvatarQueueModal(true);
      } else {
        // user quits avatar mode completely
        if (isInFullscreen) {
          exitFullscreen();
          toggleTranscript(true);
          toggleExpandedMeta(false);
        }
        // TODO(olha): add condition only if metahuman is speaking when we have "beginSpeech" event from the Avatar game
        interruptMetahuman(appUser.user_id);
        stopAvatarSession();
        updateAvatarQueue();
        clearShowAvatarQueue();
        updateChatMode(ChatMode.CHAT);
      }
    }
  };

  return (
    <button
      className={cn(styles.root, {
        [styles.active]: chatMode === ChatMode.AVATAR,
      })}
      onClick={handleClick}
    >
      {chatMode === ChatMode.AVATAR ? (
        <VideoCameraSlash size={SVG_SIZE_M} weight="fill" />
      ) : (
        <VideoCamera size={SVG_SIZE_M} />
      )}
    </button>
  );
};
