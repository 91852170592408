import { useCallback, useContext } from 'react';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { useSession } from 'src/hooks/useSession';
import { useLazyGetURLForFileUploadQuery } from 'src/store/services';

const MAX_FILE_SIZE = 100 * 1024 * 1024;
const COMMON_ERROR_MESSAGE =
  'Unable to upload the file. Please try again later';

export const useAttachFile = () => {
  const {
    setThreadInputBoxFile,
    setAttachmentLoading,
    setFileDataToSend,
    setThreadInputBoxFileError,
  } = useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);
  const { appUser } = useSession();
  const [getFileUploadUrl] = useLazyGetURLForFileUploadQuery();

  const setError = useCallback(
    (message: string) => {
      setThreadInputBoxFileError(message);
      setAttachmentLoading(false);
    },
    [setThreadInputBoxFileError, setAttachmentLoading],
  );

  const addFile = useCallback(
    async (file: File) => {
      setAttachmentLoading(true);
      setThreadInputBoxFile(file);

      if (file.size > MAX_FILE_SIZE) {
        setError(
          'Sorry, the attached file is too large to process. Please upload a smaller one and try again.',
        );
        return;
      }

      const { data, isError } = await getFileUploadUrl(
        {
          user_id: appUser.user_id,
          file_name: file.name.toLocaleLowerCase(),
        },
        false,
      );

      if (data) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data.form_data.fields)) {
          formData.append(key, value);
        }
        formData.append('file', file);

        const uploadFileUrl = data?.form_data?.url;

        fetch(uploadFileUrl, {
          method: 'POST',
          body: formData,
          mode: 'cors',
        })
          .then((response) => {
            if (response.ok) {
              setAttachmentLoading(false);
              setFileDataToSend(
                `---[original-filename="${data?.original_filename}" converted-filename="${data?.filename}"]---`,
              );
            } else {
              setError(COMMON_ERROR_MESSAGE);
            }
          })
          .catch((error) => {
            setError(COMMON_ERROR_MESSAGE);
          });
      } else if (isError) {
        setError(COMMON_ERROR_MESSAGE);
      }
    },
    [
      setFileDataToSend,
      appUser.user_id,
      getFileUploadUrl,
      setAttachmentLoading,
      setError,
      setThreadInputBoxFile,
    ],
  );

  const sendReceivedFile = useCallback(
    async (url: string) => {
      const urlParts = new URL(url);
      const filePath = urlParts.pathname;
      const fileName = filePath.split('/').pop()?.toLocaleLowerCase();

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], fileName || 'image.jpg', {
            type: `image/${fileName?.split('.')[1]}`,
            lastModified: new Date().getTime(),
          });
          setAttachmentLoading(true);
          setThreadInputBoxFile(file);

          if (file.size > MAX_FILE_SIZE) {
            setError(
              'Sorry, the attached image is too large to process. Please upload a smaller file and try again.',
            );
          } else {
            setAttachmentLoading(false);
            setFileDataToSend(`---[edit-image-url="${url}"]---`);
          }
        });
    },
    [setError, setAttachmentLoading, setThreadInputBoxFile, setFileDataToSend],
  );

  return {
    addFile,
    sendReceivedFile,
  };
};
