import { useContext, useState } from 'react';
import { PaymentPlanOption } from 'src/types';
import { PLAN_OPTIONS, SELECTED_PLAN } from 'src/constants';
import { useSession } from 'src/hooks';
import log from 'src/utils/logger';
import { useUpgradeSubscriptionMutation } from 'src/store/services';
import { PackageDeal } from '../PackageDeal';
import './UpgradeForm.scss';
import { PlanOptionWithSale } from 'src/components/UpgradeModal/components/PlanOptionWithSale';
import { Link } from 'react-router-dom';
import SessionContext from 'src/contexts/SessionContext';

export const UpgradeForm = () => {
  const { appUser, isOpenTier } = useSession();
  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);

  const defaultPlan =
    PLAN_OPTIONS.find((item) => item.isDefault) || PLAN_OPTIONS[0];

  const [selectedPlan, setSelectedPlan] =
    useState<PaymentPlanOption>(defaultPlan);

  // TODO(olha): will be implement in the next PR
  // const { data } = useGetStripePricesQuery({ user_id: appUser.user_id });

  const [upgradeSubscription, { isLoading }] = useUpgradeSubscriptionMutation();

  const onSubmit = async () => {
    const { lookup_key, promotion_code } = selectedPlan;

    try {
      const result = await upgradeSubscription({
        lookup_key,
        user_id: appUser.user_id,
        promotion_code,
      }).unwrap();

      const redirectUrl =
        result.checkout_session?.url || result.billing_portal_session?.url;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      log.error(error);
    }
  };

  const handleSignUpClick = () => {
    onToggleVisibleUpgradeModal(false);
    localStorage.setItem(SELECTED_PLAN, selectedPlan.lookup_key);
  };

  return (
    <div className="nj-upgrade-form">
      <div>
        <div className="nj-upgrade-form--options withSale">
          {PLAN_OPTIONS.map((item) => (
            <PlanOptionWithSale
              key={item.lookup_key}
              option={item}
              isActive={item.lookup_key === selectedPlan.lookup_key}
              onClick={setSelectedPlan}
            />
          ))}
        </div>
        <span className="nj-upgrade-form--sale-description">
          {selectedPlan.salePercentage}% off for your first month
        </span>
      </div>

      <PackageDeal type="pro" selectedAmount={selectedPlan.amount} />

      {isOpenTier ? (
        <Link
          to="/sign-up"
          className="nj-button nj-button--primary nj-upgrade-form--submit"
          onClick={handleSignUpClick}
        >
          Sign up for Pro
        </Link>
      ) : (
        <button
          className="nj-button nj-button--primary nj-upgrade-form--submit"
          onClick={onSubmit}
          disabled={isLoading}
          // TODO(olha): will be implement in the next PR
          // disabled={isLoading || !data}
        >
          Upgrade to PRO
        </button>
      )}
    </div>
  );
};
